import React from 'react'
import './Apk.css';

function Apk() {
  return (
    <div className='apk'>
      <div className='div'>
         <img src="./icon.png" style={{width:"35px", height:"35px"}}/>
         <div className='div1' style={{paddingLeft:"1rem"}}>
         <a href="./JSRPunt.apk" download> Download Apk</a> 
         <p>For Android Devices</p>
         </div>
      </div>
    </div>
  )
}

export default Apk
