import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className='imgcon'>
     <div id="carouselExampleSlidesOnly" className="carousel slide imgc" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div class="carousel-item active">
      <img src='./img_1.jpeg' className="d-block w-100 img"/>
    </div>
    <div class="carousel-item">
      <img src="./img_2.jpeg" className="d-block w-100 img"/>
    </div>
    <div class="carousel-item">
      <img src="./img_3.jpeg" className="d-block w-100 img"/>
    </div>
    <div class="carousel-item">
      <img src="./img_4.jpeg" className="d-block w-100 img"/>
    </div>
  </div>
</div>
    </div>
  );
}

export default App;
