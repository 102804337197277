import React from 'react'
import './Infos.css' ;

function info1() {
  return (
    <div className='info1-container'>
      <h2>YAARO KA YAAR...JSR BOOK Most Trusted Book Since 2010</h2>
      <p id='contact'>Grab your id now   <a id='contact-a' href="https://api.whatsapp.com/send?phone=+44 7537172051"> Click Here</a> </p>
      <div style={{ backgroundImage: "url(./bet_casino.jpg)",backgroundPosition: "center",
        backgroundSize: "cover", padding:"1rem .1rem", width:"100%",}}>
      <div className='ids'>
        <h3>Whitelabel</h3>
        <p><a href="https://20wicket.com">https://20wicket.com</a></p>
        <p><a href="https://bulletexch.com">https://bulletexch.com</a></p>
        <p><a href="https://Skygoldexch22.com">https://Skygoldexch22.com</a></p>
        <p><a href="https://fairechange9.com">https://fairechange9.com</a></p>
      </div>
      <div className='ids'>
        <h3>Premium</h3>
        <p><a href="https://www.skyexch.art/">https://www.skyexch.art/</a></p>
        <p><a href="https://lordsexch.com">https://lordsexch.com</a></p>
      </div>
      </div>
      <p id='bonus'>5% Bonus Flat on Deposit</p>
      <p id='contact'>Grab your id now   <a id='contact-a' href="https://api.whatsapp.com/send?phone=+44 7537172051"> Click Here</a> </p>
      <p id='panel'>Get your Panel, Super master, Mater from us.</p>
      <p id='type'>All type of sites are available</p>
      <p id='app'>For Self Deposit & Withdrawl Install the app</p>
    </div>
  )
}

export default info1
